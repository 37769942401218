<template>
	<div class="myinfo">
		<div class="myinfo" v-for="userInfo of userInfo">
			<!--个人信息-->
			<div class="userInfo">
				<div class="code">
					<!-- <img src="../../public/static/images/code.png" width="31" height="31" /> -->
				</div>
				<div class="userImg">
					<img v-bind:src="userInfo.headimgurl" height="82" width="82" style="border: 2px solid #FFFFFF;border-radius: 15px;" />
				</div>

			
				<div class="userName">{{userInfo.nickname}}</div>
				<div class="userPoint">会员积分：{{userInfo.nowscore}}</div>
				
			</div>
			<!--信息详情-->
			<div class="infos">
				<div class="name">用户类型：</div>
				<div class="info">{{userInfo.utype}}</div>
			</div>
			<div class="infos">
				<div class="name">消费总额：</div>
				<div class="info">{{userInfo.tmoney}}元</div>
			</div>
			<div class="infos">
				<div class="name">积分总额：</div>
				<div class="info">{{userInfo.totscore}}</div>
			</div>
			<div class="infos">
				<div class="name">详细地址：</div>
				<div class="info">
					<input type="text" v-model="userInfo.address" id="address">
				</div>
			</div>
			<div class="infos">
				<div class="name">微信帐号：</div>
				<div class="info">
					<input type="text" v-model="userInfo.remark1" id="remark1">
				</div>
			</div>
			<div class="infos">
				<div class="name">联系电话：</div>
				<div class="info">
					<input type="text" id="phone" v-model="userInfo.phone" />
				</div>
			</div>
			<div class="infos">
				<div class="name">家长姓名：</div>
				<div class="info">
					<input type="text" v-model="userInfo.realname" id="realname" />
				</div>
			</div>
			<div class="infos">
				<div class="name">宝宝姓名：</div>
				<div class="info">
					<input type="text" v-model="userInfo.babyname" id="babyname" />
				</div>
			</div>
			<div class="infos">
				<div class="name">宝宝症状：</div>
				<div class="info">
					<input type="text" v-model="userInfo.babydesc" id="babydesc" />
				</div>
			</div>
			<!--修改-->
			<div class="update" @click="update">
				修改
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				userInfo: {
					realname: '', //家长姓名
					address: '', //详细地址
					babyname: '', //宝贝姓名
					babydesc: '', //宝宝症状
					remark1: '', //宝宝症状
					phone: '' //手机号
				},
			}
		},
		created() {
			this.getUserInfo();
		},
		methods: {
			getUserInfo() {
				var self = this
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=me"
				}).then(result => {
					if(result.data.code == 200) {
						self.userInfo = result.data.data.rows;
					}
				})
			},
			update() {
				var self = this
				this.$store.dispatch("POST", {
					url: "/api/wx/?action=saveinfo",
					query: {
						realname: $("#realname").val(),
						address: $("#address").val(), //详细地址
						babyname: $("#babyname").val(), //宝贝姓名
						babydesc: $("#babydesc").val(), //宝宝症状
						wechat: $("#remark1").val(), //宝宝症状
						phone: $("#phone").val() //
					}
				}).then(result => {
					if(result.data.code == 200) {
						$Toast('修改成功');
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.myinfo {
		height: 100%;
		width: 100%;
	}
	
	.userInfo {
		height: 203px;
		background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #ffdaf0), color-stop(100%, #85b1f7));
		text-align: center;
		position: relative;
	}
	
	.code {
		position: absolute;
		top: 22px;
		right: 30px;
	}
	
	.userImg {
		padding-top: 22px;
	}
	
	.userName {
		font-size: 20px;
		color: #FFFFFF;
	}
	
	.userPoint {
		font-size: 14px;
		color: #fff;
	}
	
	.infos {
		height: 35px;
		line-height: 35px;
		background: #f6fafe;
		padding-left: 25px;
		margin-bottom: 5px;
		display: flex;
	}
	
	.name {
		width: 75px;
		font-size: 15px;
		color: #474747;
	}
	
	.info {
		flex: 1;
		font-size: 15px;
		color: #474747;
	}
	
	.info input {
		height: 26px;
		line-height: 26px;
		width: 70%;
		border: none;
		border-radius: 5px;
		background: #e9edf5;
		outline: none;
		padding-left: 10px;
	}
	
	.update {
		margin-top: 10px;
		background: #26a1ff;
		color: #FFFFFF;
		height: 32px;
		line-height: 32px;
		margin-left: 10%;
		margin-right: 10%;
		text-align: center;
		border-radius: 5px;
		font-size: 15px;
	}
</style>